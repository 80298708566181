.modal-info-text {
  color: #696969 !important;
  font-size: 16px;
  line-height: 28px;
}

.pricing-text {
  color: #696969;
  font-size: 20px;
  line-height: 28px;
}

.cancel-text {
  font-size: 24px;
  font-family: 'EuclidCircular-B-Bold';
}

.terms-text {
  color: #B3B3B3;
  font-size: '12px';
}

.modal-info-container {
  width: '70%';
  color: '#696969';
  line-height: '28px';
}

.loan-date-size {
  font-size: 16px;
}

.manual-disbursal-modal {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  position: absolute;
  border: 1px solid rgb(204, 204, 204);
  background: white;
  overflow: auto;
  border-radius: 1px;
  outline: none;
  padding: 20px;
}

.disable-disbursal-modal {
  position: absolute;
  border: 1px solid rgb(204, 204, 204);
  background: white;
  overflow: auto;
  border-radius: 0px;
  outline: none;
  padding: 20px;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: none;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: none;
  background: white;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 8px;
  outline: none;
  max-width: 90%;
  padding: 20px;
}

.activation-confirmation-modal {
  padding: 6px !important;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

@media (min-width: 320px) and (max-width: 374px) {
}

@media (min-width: 375px) {
  .title-subtext {
    font-size: 18px;
    line-height: 22px;
    color: #696969;
    letter-spacing: 0px !important;  
  }
}

@media (min-width: 411px) {
  .onboarding-submit {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .loan-date-size {
    font-size: 19px;
  }

  .modal-content {
    max-width: 80%;
  }
}

@media (min-width: 600px) {
  .modal-content {
    max-width: 70%;
  }
}

@media(min-width: 768px) {
  .manual-disbursal-modal {
    top: 0px;
    left: 50%;
    right: 0px;
    bottom: 0px;
  }

  .disable-disbursal-modal {
    top: 10%;
    left: 20%;
    right: 20%;
    bottom: 10%;
  }

  .modal-content {
    max-width: 60%;
  }
}

@media (min-width: 992px) {
  .loan-date-size {
    font-size: 22px;
  }

  .terms-text {
    color: #B3B3B3;
    font-size: '22px';
  }
  .pricing-text {
    color: #696969;
    font-size: 20px;
    line-height: 44px;
  }

  .modal-info-text {
    color: #696969;
    font-size: 24px;
    line-height: 44px;
  }

  .onboarding-submit {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }

  .title-margin-top {
    margin-top: 80px;
  }

  .title-subtext {
    font-size: 18px;
  }

  .modal-content {
    max-width: 50%;
  }
}

@media (min-width: 1200px) {
  .loan-date-size {
    font-size: 24px;
  }

  .modal-content {
    max-width: 40%;
  }
}

@media (min-width: 1300px) {
  .modal-content {
    max-width: 30%;
  }

  .activation-confirmation-modal {
    max-width: 40% !important;
  }
}

.activate-plus-modal {
  max-height: -webkit-fit-content !important;
  max-height: -moz-fit-content !important;
  max-height: fit-content !important;
}

.underline {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
