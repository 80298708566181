.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.menu-content {
  position: absolute;
  top: 75px;
  left: 70%;
  right: 0;
  bottom: auto;
  border: none;
  background: white;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: none;
  outline: none;
  padding: 20px;
  padding-right: 30px;
}

.menu-small-top-position {
  top: 50px;
}

@media (min-width: 991px) {
  .menu-overlay {
    display: none;
  }

  .menu-content {
    display: none;
  }
}

@media (max-width: 850px) {
  .menu-content {
    left: 65%;
  }
}

@media (max-width: 715px) {
  .menu-content {
    left: 63%;
  }
}

@media (max-width: 695px) {
  .menu-content {
    left: 60%;
  }
}

@media (max-width: 626px) {
  .menu-content {
    left: 58%;
  }
}


@media (max-width: 607px) {
  .menu-content {
    left: 50%;
  }
}

@media (max-width: 500px) {
  .menu-content {
    left: 40%;
  }
}

@media (max-width: 415px) {
  .menu-content {
    left: 30%;
  }
}

@media (max-width: 357px) {
  .menu-content {
    left: 20%;
  }
}

@media (max-width: 313px) {
  .menu-content {
    left: 10%;
  }
}

@media (max-width: 278px) {
  .menu-content {
    width: 100%;
    left: 0;
  }
}