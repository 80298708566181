.del-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
}

.del-action {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column;
}

.del-string, .del-string:visited, .del-string:hover, .del-string:active {
  cursor: pointer;
  color: #B3B3B3;
  padding-bottom: 30px;
}

.del-button {
  width: 40%;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-pack: center;
      justify-content: center;
}

@media (max-width: 1000px) {
  .del-button {
    width: 60%;
  }
}

@media (max-width: 500px) {
  .del-button {
    width: 100%;
  }
}