body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

html {
}

html, body, #root {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex: 1 1;
      flex: 1 1;
  font-family: 'EuclidCircular-B-Regular' !important;
}

a:visited {
  color: #24956a;
}
button:focus{
  outline:none !important;
}
a:hover{
  text-decoration: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@font-face {
  font-family:'EuclidCircular-B-Regular';
  src: local('EuclidCircular-B-Regular'), url('./assets/fonts/EuclidCircularB-Regular.otf') format('opentype');
}

@font-face {
  font-family:'EuclidCircular-B-Bold';
  src: local('EuclidCircular-B-Bold'), url('./assets/fonts/EuclidCircularB-Bold.otf') format('opentype');
}

@font-face {
  font-family:'EuclidCircular-B-Semibold';
  src: local('EuclidCircular-B-Seimibold'), url('./assets/fonts/EuclidCircularB-Semibold.otf') format('opentype');
}

@font-face {
  font-family:'EuclidCircular-B-Light';
  src: local('EuclidCircular-B-Light'), url('./assets/fonts/EuclidCircularB-Light.otf') format('opentype');
}

@font-face {
  font-family:'EuclidCircular-B-Medium';
  src: local('EuclidCircular-B-Medium'), url('./assets/fonts/EuclidCircularB-Medium.otf') format('opentype');
}
