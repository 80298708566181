.onboarding-submit {
  color: white !important;
  font-weight: 400 !important;
  border-color: #6c757d00 !important;
  font-family: 'EuclidCircular-B-Semibold';
  padding-top: 18px !important;
  padding-bottom: 18px !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  font-size: 18px;
  cursor: pointer;
}

.minimal, .minimal.disabled {
  font-family: 'EuclidCircular-B-Semibold';
  background-color: Transparent !important;
  background-repeat: no-repeat;
  border: none !important;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  opacity: 1 !important;
}

.inverted, .inverted.disabled {
  font-weight: 400 !important;
  border-color: #24956a !important;
  border-width: 2px;
  background-color: Transparent !important;
  font-family: 'EuclidCircular-B-Semibold';
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  font-size: 18px;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.onboarding-submit:disabled {
  opacity: .3 !important;
}

.button-text {
  color: white !important;
  padding-top: 3px;
  padding-bottom: 3px;
}

.btn-secondary.disabled {
  background-color: #24956a;
}
.onboarding-submit:hover:enabled {
  -webkit-box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
          box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
  cursor: pointer;
}

.btn:hover {
  /* here copy default .btn class styles */
  /* or something like that */
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.no-button-border {
  border: 0 !important;
}

.nohover-dg:hover {
  background: #24956a !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.nohover-lg:hover {
  background: #27D682 !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.full-width-on-mobile-with-side-nav {
  width: 40%;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-pack: center;
      justify-content: center;
}

.full-width-on-mobile {
  width: 30%;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-pack: center;
      justify-content: center;
}

@media (max-width: 1100px) {
  .full-width-on-mobile {
    width: 40%;
  }
}

@media (max-width: 1000px) {
  .full-width-on-mobile {
    width: 50%;
  }

  .full-width-on-mobile-with-side-nav {
    width: 60%;
  }
}

@media (max-width: 920px) {
  .full-width-on-mobile {
    width: 60%;
  }
}

@media (max-width: 500px) {
  .full-width-on-mobile {
    width: 100%;
  }

  .full-width-on-mobile-with-side-nav {
    width: 100%;
  }
}

@media (min-width: 411px) {
  .onboarding-submit {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
}

@media (min-width: 992px) {
  .info-text {
    font-size: 16px;
    line-height: 22px !important;
    color: #696969;
    letter-spacing: 0px !important;  
  }

  .onboarding-submit {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
}