.input.form-control:placeholder {
  color: #333333 !important;
  font-size: 18px !important;
  text-align: left;
}

.form-control {
  border: 0 !important;
  border-bottom: .5px solid #B3B3B3  !important;
  border-radius: 0rem !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  padding: 0px !important;
}
.form-control.is-invalid:focus {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  border-color: #dc3545 !important;
}

.form-group {
  height: 0px !important;
}

.password-field {
  -webkit-text-security: disc !important;
}

.warning {
  font-size: 10px !important;
  color: #ffc107;
  height: 0px !important;
}

.error {
  font-size: 10px !important;
  color:#dc3545 ;
  height: 0px !important;
}