.brigit-nav-button {
  border: none !important;
  outline: none !important;
}

.white-bold-link {
  font-family: 'EuclidCircular-B-Bold' !important; 
  color: white !important;
  font-size: 16px !important;
}

.sticky {
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 99;
}