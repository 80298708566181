.butterfly {
  width:50px;
  -webkit-animation-name: rotate;
          animation-name: rotate;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

.butterfly-container {
  -webkit-animation-name: scroll;
          animation-name: scroll;
  -webkit-animation-direction: alternate-reverse;
          animation-direction: alternate-reverse;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

.butterfly-parent-container {
  -webkit-animation-name: rotate;
          animation-name: rotate;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation-duration: 20s;
          animation-duration: 20s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

@-webkit-keyframes scroll {
  from {
      -webkit-transform: translate(-250px,0px);
              transform: translate(-250px,0px)
  }
  to {
      -webkit-transform: translate(250px,0px);
              transform: translate(250px,0px)
  }
}

@keyframes scroll {
  from {
      -webkit-transform: translate(-250px,0px);
              transform: translate(-250px,0px)
  }
  to {
      -webkit-transform: translate(250px,0px);
              transform: translate(250px,0px)
  }
}

@-webkit-keyframes rotate {
  from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg)
  }
  to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg)
  }
}

@keyframes rotate {
  from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg)
  }
  to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg)
  }
}