.cta-section {
  -ms-flex-align: center;
      align-items: center;
}

.rating-items {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
}

li {
  color: #696969 ;
}

li > p {
  margin-top: 4px;
  margin-bottom: 4px;
}

.grey {
  color: #696969;
}

@media (min-width: 992px) {
  .phone {
    position: absolute;
    top: 90px;
    width: 100%;
  }

  li > p {
    font-size: 1.10rem;
    font-weight: 200;
    margin-top:5px;
    margin-bottom:5px;
  }
}

@media (min-width: 1200px) {
  .cta-section {
    -ms-flex-align: end;
        align-items: end;
  }

  .rating-items {
    -ms-flex-direction: row;
        flex-direction: row;
  }
}

@media (min-width: 1300px) {
  .phone {
    top: 100px;
  }
}

@media (min-width: 1800px) {
  .phone {
    width: 80%;
  }
}