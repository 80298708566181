.bg-dark-green {
  background: #24956a !important;
}

.bg-light-green {
  background: #27D682 !important;
}

.bg-dark-grey {
  background-color: #f4f4f4 !important;
}

.bg-semi-lightest {
  background-color: #F5F5F5 !important;
}

.bg-lightest {
  background: #F4F4F4 !important;
}

.min-height-100vh {
  min-height: 100vh;
}

.title-margin-top {
  margin-top: 40px;
}

.euclid-medium {
  font-family: 'EuclidCircular-B-Medium';
}

.font-black {
  color: #333333 !important;
}

.field-container {
  max-width: 461px;
  margin: 0 auto;
}

input:focus, textarea:focus, button:focus {
  outline: none;
}

.center-in-parent {
  margin: 0 auto;
}

.font-dark-green {
  color: #24956a !important;
}

.font-lg {
  color: #27D682 !important;
}

.font-black {
  color: #333333 !important;
}
.font-light-grey {
  color: #B3B3B3 !important;
}

.font-dark-grey {
  color: #333333 !important;
}

.font-grey {
  color: #696969 !important;
}

.font-white {
  color: #FFFFFF !important;
}

.font-light-blue {
  color: #00AEFF !important;
}

.semi-bold {
  font-family: 'EuclidCircular-B-Semibold' !important;
}

.bold {
  font-family: 'EuclidCircular-B-Bold' !important;
}

.regular {
  font-family: 'EuclidCircular-B-Regular' !important;
}

.dk-font {
  color: #333333 !important;
}

.brig-sm {
  font-size: 14px !important;
}

.brig-h3 {
  font-size: 22px;
  line-height: 28px;
  font-family: 'EuclidCircular-B-Bold';
}

.brig-h5 {
  font-size: 14px;
  line-height: 18px;
  font-family: 'EuclidCircular-B-Bold';
}

.brig-p {
  font-size: 16px;
  line-height: 28px;
}

.brig-button-text {
  font-size: 15px;
  line-height: 20px
}

.info-text {
  font-size: 14px;
  line-height: 1.652rem !important;
  color: #696969;
  letter-spacing: 0px !important;  
}

.font-color-lg {
  color: #696969 !important;
}

.font-color-secondary {
  color: #00AEFF !important;
}

.font-color-danger {
  color: #E74858 !important;
}

.font-color-primary {
  color: #0EAD79 !important;
}

/** Material design **/

.MuiFormLabel-root-16 {
  font-family: 'EuclidCircular-B-Regular' !important;
}

.MuiAutocomplete-input {
  font-family: 'EuclidCircular-B-Light' !important;
  color: #333333 !important;
}

.MuiAutocomplete-listbox {
  padding-top: 8px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  padding-bottom: 0px !important;
}

.MuiAutocomplete-option {
  padding-left: 5px !important;
  padding-right: 5px !important;
  border-bottom: 0.2px solid #B3B3B3;
  min-height: 0px !important;
}

.MuiAutocomplete-option[aria-disabled="true"] {
  opacity: 1 !important;
}

.MuiPickersDay-daySelected {
  background-color:  #24956a !important;
  color: #FFF !important;
}

.MuiPickersDay-current {
  color: #27D682 !important;
}

.field-container > div:after {
  border-bottom: 0px !important;
}

.debit-form-section {
  max-width: 450px;
  margin: 0 auto;
}

.debit-form-section .Select-placeholder {
  color: rgba(117, 117, 117, 1);
  padding: 0;
}

.debit > .Select {
  z-index: 3;
}

.Select.is-focused:not(.is-open) > .Select-control {
  border-color: #333333 !important;
}

.Select-option.is-focused {
  background-color:#37bf8b87;
}

.Select-option.is-selected {
  background-color:#37bf8b87; 
}

.debit-form-section > .Select-value {
  padding-left: 0px !important;
}

.debit-form-section > .Select-input {
  padding-left: 0px !important;
}

.sk-spinner {
  color: #24956a;
}

.ball-clip-rotate > div {
  -webkit-animation: rotate .9s 0s linear infinite;
          animation: rotate .9s 0s linear infinite;
  height: 200px;
  width: 200px;  
}


@media (min-width: 320px) and (max-width: 374px) {
}

@media (min-width: 375px) {
  .title-subtext {
    font-size: 18px;
    line-height: 22px;
    color: #696969;
    letter-spacing: 0px !important;  
  }
}

@media (min-width: 411px) {
  .onboarding-submit {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
}

@media (min-width: 992px) {
  .info-text {
    font-size: 16px;
    line-height: 22px !important;
    color: #696969;
    letter-spacing: 0px !important;  
  }

  .onboarding-submit {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }

  .title-margin-top {
    margin-top: 80px;
  }

  .title-subtext {
    font-size: 18px;
  }
  
  .mr-lg-6 {
    margin-right: 1rem !important;
  }

  .ml-lg-6 {
    margin-left: 1rem !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg-6 {
    margin-left: 1rem !important;
  }
  .mr-lg-6 {
    margin-right: 5rem !important;
  }
}

@media (min-width: 1300px) {
}

.grecaptcha-badge { visibility: hidden;}